import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Image from "gatsby-image"
import { v4 as uuidv4 } from 'uuid';

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Ads from "../components/ads"
import Adsense1 from "../components/adsense1"
import Adsense2 from "../components/adsense2"
import Adsense3 from "../components/adsense3"

const chunk = require(`lodash/chunk`)

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, pageNumber, totalPages },
  location
}) => {
  const posts = data.allWpPost.nodes
  const terms = data.allWpTermNode.nodes[0]

  if (data.allWpTermNode.nodes[1]) {
    terms.name = null
    terms.description = null
  }
  terms.page = pageNumber
  terms.pagesTotal = totalPages

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <SEO title="Strona główna" />
        {/* <Bio /> */}
        <p>
          Brak wpisów!
        </p>
      </Layout>
    )
  }

  if (terms.name === null) {
    terms.name = "Blog kulinarny Pauliny Stępień"
    terms.home = true
  }

  terms.seo = terms.name

  if (terms.page > 1) {
    terms.seo = `${terms.name} - strona ${terms.page}`
  }

  const postsChunked = chunk(posts, 4)

  // ustawienia wp: 16 postów na stronę

  const reklama1 = {ad:true, adslot: 1, classname: 'ad', id: uuidv4()}
  const reklama2 = {ad:true, adslot: 2, classname: 'ad', id: uuidv4()}
  const reklama3 = {ad:true, adslot: 3, classname: 'ad', id: uuidv4()}

  var postsChunked1
  var postsChunked2
  var postsChunked3

  var postsMerged

  if (postsChunked.length === 4) {
    postsChunked1 = postsChunked[0].concat(reklama1)
    postsChunked2 = postsChunked[1].concat(reklama2)
    postsChunked3 = postsChunked[2].concat(reklama3)
    postsMerged = [...postsChunked1, ...postsChunked2, ...postsChunked3, ...postsChunked[3]]
  } 
  else if (postsChunked.length === 3) {
    postsChunked1 = postsChunked[0].concat(reklama1)
    postsChunked2 = postsChunked[1].concat(reklama2)
    postsMerged = [...postsChunked1, ...postsChunked2, ...postsChunked[2]]
  }
  else if (postsChunked.length === 2) {
    postsChunked1 = postsChunked[0].concat(reklama1)
    postsMerged = [...postsChunked1, ...postsChunked[1]]
  }
  else {
    postsMerged = posts
  }

  const classname = 'archive-item'

  return (
    <Layout currentPath={location.pathname} isHomePage={terms?.home} homePageNumber={terms?.page} totalPageNumber={terms?.pagesTotal} archiveTitle={terms?.name} archiveDesc={terms?.description}>
      <SEO title={terms?.seo} description={terms?.description} />

      {/* <Bio /> */}

      {/* <ol style={{ listStyle: `none` }}> */}
        {postsMerged.map(post => {
          const title = post.title
          const featuredImage = {
            fluid: post?.featuredImage?.node?.localFile?.childImageSharp?.fluid,
            alt: post?.featuredImage?.node?.alt || ``,
          }

          return (
            <div key={post.id} className={post.classname || classname}>

              {!!post.ad ? (
                post.adslot === 1 ? (
                  <Adsense1 currentPath={location.pathname} />
                ) : post.adslot === 2 ? (
                  <Adsense2 currentPath={location.pathname} />
                ) : post.adslot === 3 && (
                  <Adsense3 currentPath={location.pathname} />
                )
              ) : (

                <article>

                  <header>
                    {/* if we have a featured image for this post let's display it */}
                    {featuredImage?.fluid && (
                      <Link to={post.uri}>
                        <Image
                        fluid={featuredImage.fluid}
                        alt={featuredImage.alt}
                        />
                      </Link>
                    )}
                    <h1>
                      <Link to={post.uri}>
                        <span>{parse(title)}</span>
                      </Link>
                    </h1>
                    {/* <small>{post.date}</small> */}
                  </header>
                  <section>
                    {parse(post.excerpt)}
                  </section>
                  <nav className="read-more">
                    <Link to={post.uri}>Czytaj dalej →</Link>
                  </nav>
                </article>
              )}

            </div>
          )
        })}
      {/* </ol> */}

      {(previousPagePath || nextPagePath ) && (
        <nav className="archive-nav"><ul>
        {(previousPagePath && (<li className="prev"><Link to={previousPagePath}>← Wstecz</Link></li>))}
        
        {(nextPagePath && (<li className="next"><Link to={nextPagePath}>Dalej →</Link></li>))}
        </ul></nav>
      )}

      <Ads currentPath={location.pathname} />
      
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!, $termDatabaseId: Int) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: {
        terms: {
          nodes: { elemMatch: { databaseId: { eq: $termDatabaseId } } }
        }
      }
    ) {
      nodes {
        id
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 85) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    allWpTermNode(filter: {databaseId: {eq: $termDatabaseId}}) {
      nodes {
        name
        description
      }
    }
  }
`
