import React from "react"
import { useEffect } from 'react';
import { getCookieConsentValue } from "react-cookie-consent";
// import { useLocation } from 'react-router-dom';
// import { location } from "@reach/router"

const Ads = ({ currentPath }) => {
  // const { location } = props
  // const { currentPath } = props

  // console.log(currentPath)

  // const location = useLocation();
  // const currentPath = location.pathname;

  const getGPDRCookie = getCookieConsentValue('gdpr_non-essential-enabled')

  useEffect(() => {
    
    // const getGPDRCookie = getCookieConsentValue('gdpr_non-essential-enabled')

    const adrino = document.createElement('script');
    adrino.src = '//mob.vendimob.pl/rp/98caa9d58f8f9347/1';
    adrino.async = true;

    // window.adsbygoogle = window.adsbygoogle || []
    // window.adsbygoogle.push({})

    if ((getGPDRCookie === 'true') && (typeof window !== 'undefined') && (window.innerWidth <= 991)) {
      document.head.appendChild(adrino)
    }

    return () => {
      if ((getGPDRCookie === 'true') && (typeof window !== 'undefined') && (window.innerWidth <= 991)) {
        document.head.removeChild(adrino)
      }
    } 

  }, [currentPath, getGPDRCookie])
  
  return (
    <aside key={currentPath} className="ad-placement">
      <div id="vm_98caa9d58f8f9347"></div>
    </aside>
  )
}

export default Ads