import React from "react"
import { useEffect } from 'react';
import { getCookieConsentValue } from "react-cookie-consent";
// import { useLocation } from 'react-router-dom';
// import { location } from "@reach/router"

const Adsense3 = ({ currentPath }) => {
  // const { location } = props
  // const { currentPath } = props

  // console.log(currentPath)

  // const location = useLocation();
  // const currentPath = location.pathname;

  const getGPDRCookie = getCookieConsentValue('gdpr_non-essential-enabled');

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, [currentPath, getGPDRCookie])
  
  return (
    <div key={currentPath} className="ad-placement">
      <ins className="adsbygoogle"
      // style="display:block"
      data-ad-client="ca-pub-1889719438853322"
      data-ad-slot="2591186310"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
    </div>
  )
}

export default Adsense3